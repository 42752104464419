import DataManager from "./dtm";
import { fbDatabase } from "../firebase/firebase";


class ConvenioDB extends DataManager {

    state = {
        collection: 'convenios',
        orderBy: ['razaoSocial', 'nomeFantasia'], // usado na classe pai
    }

    async getByOwnerIdCustom(gestorId) {

        const snapshot = await fbDatabase.collection(this.state.collection)
            .where("ownerId", "==", gestorId)
            .orderBy("nomeFantasia")
            .orderBy("razaoSocial")
            .get();

        const data = snapshot.docs.map((item) => ({
            key: item.id,
            ...item.data(),
            prazoStr: item.data().gerarCobranca ? item.data().prazo : '--',
            ativoStr: item.data().ativo ? 'Sim' : 'Não',
            gerarCobrancaStr: item.data().gerarCobranca ? 'Sim' : 'Não',
        }));
        return data;
    }

    async getByOwnerIdAndAtivo(ownerId, ativo) {

        const snapshot = await fbDatabase.collection(this.state.collection)
            .where("ownerId", "==", ownerId)
            .where("ativo", "==", ativo)
            .orderBy("razaoSocial")
            .orderBy("nomeFantasia")
            .get();

        const data = snapshot.docs.map((item) => ({
            key: item.id,
            ...item.data(),
            ativoStr: item.data().ativo ? 'Sim' : 'Não',
        }));
        return data;
    }

    async getByOwnerIdAndAdminId(ownerId, administradorId) {
        const snapshot = await fbDatabase.collection(this.state.collection)
            .where("ownerId", "==", ownerId)
            .where("administrador.id", "==", administradorId)
            .orderBy("razaoSocial")
            .orderBy("nomeFantasia")
            .get();

        const data = snapshot.docs.map((item) => ({
            key: item.id,
            ...item.data(),
            ativoStr: item.data().ativo ? 'Sim' : 'Não',
        }));
        return data;
    }


    async getByOwnerIdAndCreditoTrue(gestorId) {
        const snapshot = await fbDatabase.collection(this.state.collection)
            .where("ownerId", "==", gestorId)
            .where("credito.ativo", "==", true)
            .orderBy("nomeFantasia")
            .orderBy("razaoSocial")
            .get();

        const data = snapshot.docs.map((item) => ({
            key: item.id,
            ...item.data(),
            prazoStr: item.data().gerarCobranca ? item.data().prazo : '--',
            ativoStr: item.data().ativo ? 'Sim' : 'Não',
            gerarCobrancaStr: item.data().gerarCobranca ? 'Sim' : 'Não',
        }));
        return data;
    }



    async getByCNPJ(cnpj) {
        const data = await this.getByParamValue('cnpj', cnpj);
        return data;
    }

    async getByEmail(email) {
        const data = await this.getByParamValue('email', email);
        return data;
    }
}

const convenioDB = new ConvenioDB();
export default convenioDB;