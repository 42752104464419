import React from 'react';
import { withRouter } from 'react-router';
import PageLayout from '../../../components/layout/PageLayout';
import BarChart from '../../../components/charts/barChart';
import { Select, DatePicker, Button, message, Card, Table } from 'antd';
import { buildChart } from './Funcs-Movimentos';
import locale from 'antd/es/date-picker/locale/pt_BR';
import vertteStorage from '../../../components/localstorage/localstorage';
import moment from 'moment';
import './Movimentos.css'
import { currentUserCol, getUserType } from '../../../components/auth/auth-provider';
import mensagem from '../../../components/messages/message';
import history from '../../../history';
import funcs from '../../../components/funcs/funcs';


const { Option } = Select
const { RangePicker } = DatePicker;
const dateFormat = 'DD/MM/YYYY'

// const CINZA = "#9d9e9d";
const VERDE = '#32a852';
const LARANJA = '#ebb134';
const ROXO = '#a234eb';

class Movimentos extends React.Component {

    state = {
        loading: true,
        periodo: {},
        alterouConfiguracaoRelatorio: false,
        options: {},
        data: {
            labels: [],
            datasets: []
        },
        showBeneficiarios: false,
        cardDebito: '',
        cardCredito: '',
        cardCreditoPre: '',
        cardSaldo: '',
        beneficiarioSaldos: {},
        saldoBeneficiario: '',
        columns: [],
        tableData: []
    }

    options = [];
    beneficiariosOptions = [];

    constructor(props) {
        super(props)
        this.onSelectChange = this.onSelectChange.bind(this);
        this.onDateChange = this.onDateChange.bind(this);
        this.onFilterClick = this.onFilterClick.bind(this);
        this.onSelectBeneficiarioChange = this.onSelectBeneficiarioChange.bind(this);
        this.mountChart = this.mountChart.bind(this);
        this.buildColums = this.buildColums.bind(this);

    }

    async componentDidMount() {
        this.setState({ loading: false });

        const storage = JSON.parse(vertteStorage.get('@reports/movimentos'));

        if (storage) {
            this.setState({
                periodo: {
                    inicial: moment(storage.periodo.inicial),
                    final: moment(storage.periodo.final),
                },
                tableData: storage.tableData,
                cardCredito: storage.cardCredito,
                cardCreditoPre: storage.cardCreditoPre,
                cardDebito: storage.cardDebito,
                cardSaldo: storage.cardSaldo,
                beneficiarioSaldos: storage.beneficiarioSaldos,
                columns: storage.columns,
            })


            for (let b of storage.beneficiariosOptions) {
                this.beneficiariosOptions.push(<Option key={b.key} value={b.key}>{b.props.children}</Option>)
            }

            this.mountChart(storage.chartData)
        }

    }

    async onSelectChange(value) {
        this.beneficiariosOptions = [];

        // const b = await getBeneficiarios(value);
        
        // let saldos = {};

        // this.beneficiariosOptions.push(<Option key='todos' value='todos'>Todos</Option>)
        // for (const item of b) {
        //     saldos[item.key] = item.saldo ? (item.saldo / 100) : item.operacao ? (item.operacao.saldo / 100) : 0;
        //     this.beneficiariosOptions.push(<Option key={item.key} value={item.key}>{item.nome + ' ' + item.sobrenome} </Option>)
        // }

        // this.setState({ alterouConfiguracaoRelatorio: true, beneficiarioSaldos: saldos })
    }

    onSelectBeneficiarioChange(value, object) {
        const beneficiario = {
            id: value,
            nome: object.props.children
        }
        this.setState({
            beneficiario,
            data: [],
            cardCredito: '',
            cardCreditoPre: '',
            cardDebito: '',
            cardSaldo: '',
        })
    }

    onDateChange(value) {
        const periodo = {
            inicial: value[0],
            final: value[1]
        }
        this.setState({ periodo, alterouConfiguracaoRelatorio: true });
    }

    async onFilterClick() {

        this.buildColums();


        if (!this.state.periodo.inicial || !this.state.periodo.final) {
            message.warning("Selecione um período");
            return;
        }


        this.setState({ loading: true })

        const data = await buildChart(this.state.periodo.inicial, this.state.periodo.final);

        this.mountChart(data.chartData);

        const tableData = data.tableData;

        const storageElement = {
            convenioId: this.state.convenioId,
            beneficiario: this.state.beneficiario,
            periodo: this.state.periodo,
            chartData: data.chartData,
            tableData: tableData,
            cardCredito: this.state.cardCredito,
            cardCreditoPre: this.state.cardCreditoPre,
            cardDebito: this.state.cardDebito,
            cardSaldo: this.state.cardSaldo,
            beneficiarioSaldos: this.state.beneficiarioSaldos,
            columns: this.state.columns,
            beneficiariosOptions: this.beneficiariosOptions,
        }

        vertteStorage.set('@reports/movimentos', JSON.stringify(storageElement));

        this.setState({ tableData })

        this.setState({ loading: false, showBeneficiarios: true })

    }

    async mountChart(chartData) {
        let dataSet = [
            {
                label: "DÉBITO",
                data: [],
                backgroundColor: LARANJA,
            },
            {
                label: 'CRÉDITO',
                data: [],
                backgroundColor: VERDE,
            },
            {
                label: 'CRÉDITO PRÉ',
                data: [],
                backgroundColor: ROXO,
            },
        ]

        let labels = [];

        let debitoTotal = 0;
        let creditoTotal = 0;
        let creditoPreTotal = 0;

        chartData.forEach((item) => {
            debitoTotal += item.debito;
            creditoTotal += item.credito;
            creditoPreTotal += item.creditoPre;
            dataSet[0].data.push(item.debito);
            dataSet[1].data.push(item.credito);
            dataSet[2].data.push(item.creditoPre);
            const date = new Date(item.data);
            labels.push(date.getDate() + "/" + (date.getMonth() + 1))
        });

        const data = {
            labels: labels,
            datasets: dataSet
        }

        debitoTotal = 'R$ ' + debitoTotal.toFixed(2);
        creditoTotal = 'R$ ' + creditoTotal.toFixed(2);
        creditoPreTotal = 'R$ ' + creditoPreTotal.toFixed(2);

        const saldo = 0; // this.state.beneficiario.id === 'todos' ?
            // 0 : (!this.state.beneficiarioSaldos[this.state.beneficiario.id]) ?
            //     0 : this.state.beneficiarioSaldos[this.state.beneficiario.id];

        const formatedSaldo = 'R$ ' + saldo.toFixed(2)

        this.setState({
            cardDebito: debitoTotal,
            cardCredito: creditoTotal,
            cardCreditoPre: creditoPreTotal,
            cardSaldo: formatedSaldo,
            data
        });
    }

    buildColums() {
        const columns = [
            {
                title: 'Estabelecimento',
                dataIndex: 'estabelecimento.nome',
                key: 'estabelecimento.nome'
            },
            {
                title: 'Débito',
                dataIndex: 'debito',
                key: 'debito'
            },
            {
                title: 'Forma pagto',
                dataIndex: 'tipoDebito',
                key: 'tipoDebito'
            }
        ]

        this.setState({ columns })
    }


    render() {
        return (
            <div>
                <PageLayout
                    selectItem={"relatorios/movimentacao"}>
                    <div className="containerMovimentacao">
                        <label>Período: </label>
                        <RangePicker
                            style={{ width: '25%' }}
                            locale={locale}
                            disabled={this.state.loading}
                            format={dateFormat}
                            placeholder={['Data Inicial', 'Data Final']}
                            onChange={this.onDateChange}
                            value={[this.state.periodo.inicial, this.state.periodo.final]}
                        />
                        <Button loading={this.state.loading} onClick={this.onFilterClick} type="primary">Filtrar</Button>
                    </div>

                    <label className='subtituloMovimentos'>Débito/Crédito por Dia: </label>
                    <BarChart className="chartMovimento"
                        data={this.state.data}

                        options={{
                            tooltips: {
                                callbacks: {
                                    label: function (tooltipItem, data) {

                                        let label = data.datasets[tooltipItem.datasetIndex].label;
                                        let value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];

                                        value = 'R$ ' + value.toFixed(2);

                                        return ' ' + label + ': ' + value
                                    }
                                }
                            },
                            scales: {
                                yAxes: [
                                    {
                                        ticks: {
                                            beginAtZero: true,
                                        }

                                    }
                                ]
                            },
                            layout: {
                                padding: {
                                    top: 20,
                                    right: 40,
                                    left: 40,
                                    bottom: 20,
                                }
                            },
                            plugins: {
                                datalabels: {
                                    display: true,
                                    anchor: "end",
                                    align: "top",
                                    formatter: function (value, context) {
                                        if (value === undefined) 
                                        {
                                            return 0
                                        } else if (value === 0) {
                                            return value;
                                        } else {
                                            return 'R$ ' + (value.toFixed(2))
                                        }
                                    }
                                },
                            },
                        }}
                    />

                    <label className='subtituloMovimentos'>Débito por Estabelecimento: </label>
                    <div className="tableEstabelecimentos">
                        <Table
                            columns={this.state.columns}
                            dataSource={this.state.tableData}
                        >

                        </Table>
                    </div>
                </PageLayout>
            </div>
        )
    }

}

export default withRouter(Movimentos);

