import firebase from 'firebase';

const USE_LOCAL_API = false;

const IDX_MOUB_DEV = 0;
const IDX_MOUB = 1;
const IDX_CONVENIO = 2;

const PROJECTS = [{
    id: IDX_MOUB_DEV,
    project: 'payments-4c',
    title: 'Moub Admin',
    meta: {
        description: 'Moub Admin',
        keywords: ''
    }
}, {
    id: IDX_MOUB,
    project: 'moub-4c',
    title: 'Moub Admin',
    meta: {
        description: 'Moub Admin',
        keywords: ''
    }
}, {
    id: IDX_CONVENIO,
    project: 'convenio-37457',
    title: 'Regina+ Admin',
    meta: {
        description: 'Moub Admin',
        keywords: ''
    }
}];
const PROJECT = PROJECTS[IDX_MOUB];

let config = {
    apiKey: "AIzaSyDQ8SDb6xFuOjIUlNUM7t2t5WIEPm3sRAw",
    authDomain: "payments-4c.firebaseapp.com",
    databaseURL: "https://payments-4c.firebaseio.com",
    projectId: "payments-4c",
    storageBucket: "payments-4c.appspot.com",
    messagingSenderId: "312148104664",
    appId: "1:312148104664:web:eed975ee78ac14fa638ead",
    measurementId: "G-8B4HJFJ1BM"
};

// MOUB PROJECT
if (PROJECT.id === IDX_MOUB) {
    config = {
        apiKey: "AIzaSyCnDELxZ6UX1NCZbd0lblebV6ApnNOrGjg",
        authDomain: "moub-4c.firebaseapp.com",
        databaseURL: "https://moub-4c.firebaseio.com",
        projectId: "moub-4c",
        storageBucket: "moub-4c.appspot.com",
        messagingSenderId: "275586078746",
        appId: "1:275586078746:web:cf3d2eb01f594425a219a3",
        measurementId: "G-KZBWG8YLQ4"
    };
} else if (PROJECT.id === IDX_CONVENIO) {
    // REGINA PROJECT
    config = {
        apiKey: "AIzaSyCJy1fxwk8pC3QZf1mXq395_ceMn2kVQvk",
        authDomain: "convenio-37457.firebaseapp.com",
        databaseURL: "https://convenio-37457.firebaseio.com",
        projectId: "convenio-37457",
        storageBucket: "convenio-37457.appspot.com",
        messagingSenderId: "1066587673440",
        appId: "1:1066587673440:web:0e6500a57ad7820aabc405",
        measurementId: "G-XF69M33XJJ"
    };
}

export const fireBase = firebase.initializeApp(config);
export const fbDatabase = firebase.firestore();
export const Timestamp = firebase.firestore.Timestamp;
export const fieldPath = firebase.firestore.FieldPath;

function getProject() {
    if (!USE_LOCAL_API) {
        return config.projectId;
    }

    return PROJECT;
}

export const API_ROUTER = `https://us-central1-${getProject()}.cloudfunctions.net/api/v1/`;;
export const API_ROUTER_CHARGE = `https://us-central1-${getProject()}.cloudfunctions.net/api/v1/charge/`;
export const UPDATE_USER_URL = `https://us-central1-${getProject()}.cloudfunctions.net/api/v1/update-user`;
export const UPDATE_MAIL_USER_URL = `https://us-central1-${getProject()}.cloudfunctions.net/api/v1/update-mail-user`;
export const APPCONF = PROJECT;