import DataManager from "./dtm";
import { fbDatabase } from "../firebase/firebase";


class ClienteDB extends DataManager {

    state = {
        collection: 'clientes',
        orderBy: ['razaoSocial', 'nomeFantasia'], // usado na classe pai
    }

    async get(lastDocId) {

        let query = fbDatabase
            .collection(this.state.collection)
            .orderBy('razaoSocial')
            .orderBy('nomeFantasia');

        if (lastDocId) {
            const doc = await fbDatabase
                .collection(this.state.collection)
                .doc(lastDocId)
                .get();

            query = query.startAfter(doc);
        }

        const snapshot = await query
            .limit(50)
            .get();

        const data = snapshot.docs.map((item) => ({
            key: item.id,
            ...item.data(),
            ativoStr: item.data().ativo ? 'Sim' : 'Não',
        }));

        return data;
    }

    async getByCNPJ(cnpj) {
        const data = await this.getByParamValue('cnpj', cnpj);
        return data;
    }

    async getByEmail(email) {
        const data = await this.getByParamValue('email', email);
        if (data !== undefined) {
            data[0].ativoStr = data[0].ativo ? 'Sim' : 'Não';
        }
        return data;
    }
}

const clienteDB = new ClienteDB();
export default clienteDB;