import DataManager from "./dtm";
import { fbDatabase, fieldPath } from "../firebase/firebase";


async function queryBy10(ownerId, ids, dataIni, dataFim) {
    let snapshot = await fbDatabase.collection('movimentos')
        .where("ownerId", "==", ownerId)
        .where("data", ">=", dataIni)
        .where("data", "<=", dataFim)
        .where("convenioId", "in", ids)
        .get();

    if (snapshot.empty) {
        return [];
    }

    const data = snapshot.docs.map((item) => ({
        key: item.id,
        ...item.data(),
    }));

    return data;
}

class MovimentoDB extends DataManager {

    state = {
        collection: "movimentos"
    }

    async getByBeneficiarioAndPeriodo(beneficiarioId, dataIni, dataFinal) {
        const snapshot = await fbDatabase.collection(this.state.collection)
            .where("beneficiarioId", "==", beneficiarioId)
            .where("data", ">=", dataIni)
            .where("data", "<=", dataFinal)
            .get();

        const data = snapshot.docs.map((doc) => ({
            key: doc.id,
            ...doc.data()
        }))

        return data;
    }

    async getByEstabelecimentoAndPeriodo(estabelecimentoId, dataIni, dataFinal) {
        const snapshot = await fbDatabase.collection(this.state.collection)
            .where("autorizacao.estabelecimento.key", "==", estabelecimentoId)
            .where("data", ">=", dataIni)
            .where("data", "<=", dataFinal)
            .get();

        const data = snapshot.docs.map((doc) => ({
            key: doc.id,
            ...doc.data()
        }))

        return data;
    }

    async getByPeriodo(dataIni, dataFinal, ownerId, conveniosIds) {
        if (conveniosIds && conveniosIds.constructor !== Array) {
            const conv = []
            conv.push({ key: conveniosIds });
            conveniosIds = conv;
        }

        let movimentos = [];
        if (!conveniosIds || conveniosIds.length === 0) {
            let snapshot = fbDatabase.collection(this.state.collection)
                .where("data", ">=", dataIni)
                .where("data", "<=", dataFinal)

            if (ownerId) {
                snapshot += snapshot.where("ownerId", "==", ownerId)
            }
            snapshot = await snapshot.get();

            const data = snapshot.docs.map((item) => ({
                key: item.id,
                ...item.data(),
            }));
            movimentos.push.apply(movimentos, data);
            return movimentos;
        }

        // Quando houver convenios ids informados na lista

        let ids = [];
        if (!conveniosIds) {
            conveniosIds = []
        }

        for (let index = 0; index < conveniosIds.length; index++) {
            ids.push(conveniosIds[index].key);
            if (ids.length >= 10) {
                const data = await queryBy10(ownerId, ids, dataIni, dataFinal);
                movimentos.push.apply(movimentos, data);
                ids = [];
            }
        }

        if (ids.length > 0) {
            const data = await queryBy10(ownerId, ids, dataIni, dataFinal);
            movimentos.push.apply(movimentos, data);
        }

        return movimentos;
    }




    async getUltimoCredito(ownerId) {
        const snapshot = await fbDatabase.collection(this.state.collection)
            //.where('ownerId', '==', ownerId)
            .where(fieldPath.documentId(), '>=', 'ped')
            .where(fieldPath.documentId(), '<=', 'pee')
            .get();

        const data = snapshot.docs.map((doc) => ({
            key: doc.id,
            ...doc.data()
        }))

        const reduced = data.reduce((result, item) => {
            const id = item.beneficiarioId;

            result[id] = result[id] || [];

            result[id] = result[id].data ?
                item.data.seconds > result[id].data.seconds ? item : result[id]
                :
                item

            return result;
        }, {});


        return reduced;
    }

    async getLastPurchase(beneficiarioId) {
        const snapshot = await fbDatabase.collection(this.state.collection)
            .where('beneficiarioId', '==', beneficiarioId)
            .orderBy('data', 'desc')
            .limit(1)
            .get();

        const data = snapshot.docs.map((doc) => ({
            key: doc.id,
            ...doc.data()
        }));

        let lastPurchase = undefined;
        for (let index = 0; index < data.length; index++) {
            const movimento = data[index];
            if (movimento.debito > 0 && !lastPurchase) {
                lastPurchase = movimento;
            }
        }

        if (lastPurchase) {
            lastPurchase.saldo = data[0].saldo;
        }

        return lastPurchase;
    }
}

const movimentoDB = new MovimentoDB();
export default movimentoDB;