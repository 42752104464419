// PAGE TITLES
const TITULO_DEFAULT = 'Regina+';

export const H_01_HORA = 3600000;
export const H_24_HORAS = H_01_HORA * 24;

export const TITULO_PEDIDOS = `${TITULO_DEFAULT} - Pedidos`;


// COLLECTI0NS
export const CL_CONVENIOS = 'convenios';
export const CL_PEDIDOS = 'pedidos';
export const CL_ESTABELECIMENTOS = 'estabelecimentos';

// INDEXES
export const IDX_GESTOR_ID = 'gestorId';
export const IDX_CONVENIO_ID = 'convenio.id';
export const IDX_BOLETO_STATUS = 'boleto.status';
export const IDX_DATA_INICIAL = 'dataInicial';

export const POS_PAGO = 'posPago';
export const PRE_PAGO = 'prePago';



// DIVERSOS
export const DATE_FORMAT = 'DD/MM/YYYY';
export const DATA_NAO_MENOR_HOJE = 'A data não pode ser menor ou igual a de hoje';

// DIVERSOS PEDIDOS
export const PEDIDO_JA_FOI_PROCESSADO_CANCELADO = 'Pedido já foi processado e não pode mais ser cancelado.';
export const PEDIDO_CONFIRMAR_CANCELAMENTO = 'Deseja realmente cancelar este pedido? Essa operação não poderá ser desfeita.'
export const PEDIDO_CANCELAMENTO_CONFIRMACAO_TITLE = 'Pedido Cancelado';
export const PEDIDO_CANCELAMENTO_CONFIRMACAO_DESC = 'Seu pedido foi cancelado com sucesso';
export const PEDIDO_CANCELAMENTO_ERRO_TITLE = 'Error';
export const PEDIDO_CANCELAMENTO_ERRO_DESC = 'Houve um problema ao tentar cancelar o boleto. Tente novamente';
export const PEDIDO_VENCIMENTO_ALTERADO_TITLE = 'Sucesso';
export const PEDIDO_VENCIMENTO_ALTERADO_DESC = 'Data de vencimento alterada com sucesso';
export const PEDIDO_VENCIMENTO_ALTERADO_ERRO_TITLE = 'Erro';
export const PEDIDO_VENCIMENTO_ALTERADO_ERRO_DESC = 'Houve uma falha ao tentar alterar a data de vencimento. Tente novamente';
export const PEDIDO_LINHA_DIGITAVEL_COPIADA = 'Linha digitável copiada com sucesso';
export const ESTABELECIMENTO_NOVA_CHAVE_API = 'Essa operação irá substituir a chave atual. Isso fará com que a integração deste estabelecimento para de funcionar até que a nova chave seja distribuída. Deseja mesmo fazer isso?';



// USER TYPES
export const USER_ADMINISTRADOR = 'administrador';
export const USER_CONVENIADO = 'conveniado';

// USER PERMISSIONS
export const USER_CONVENIO_PEDIDOS = 'convenio-pedidos';
export const USER_CONVENIO_PEDIDOS_CADASTRAR = 'convenio-pedidos-cadastrar';
export const USER_CONVENIO_PEDIDOS_CANCELAR = 'convenio-pedidos-cancelar';

export const USER_CONVENIADOR_PEDIDOS = 'conveniador-pedidos';
export const USER_CONVENIADOR_PEDIDOS_EDITAR = 'conveniador-pedidos-editar';
export const USER_CONVENIADOR_PEDIDOS_CANCELAR = 'conveniador-pedidos-cancelar';

// ACCESS DENIED
export const ACCESS_DENIED = 'Você não tem permisão para acessar essa página.';



