import DataManager from "./dtm";
import { fbDatabase } from "../firebase/firebase";


class AdministradorDB extends DataManager {

    state = {
        collection: 'administradores',
        orderBy: ['razaoSocial', 'nomeFantasia'], // usado na classe pai
    }

    async getByOwnerIdCustom(gestorId) {

        const snapshot = await fbDatabase.collection(this.state.collection)
        .where("ownerId", "==", gestorId)
        .orderBy("razaoSocial")
        .orderBy("nomeFantasia")
        .get(); 

        const data = snapshot.docs.map((item) => ({
            key: item.id,
            ...item.data(),
            ativoStr: item.data().ativo ? 'Sim' : 'Não',
          }));
        return data;
    }

    async getByCNPJ(cnpj) {
        const data = await this.getByParamValue('cnpj', cnpj);
        return data;
    }

    async getByEmail(email) {
        const data = await this.getByParamValue('email', email);
        data[0].ativoStr =  data[0].ativo ? 'Sim' : 'Não';
        return data;
    }    
}

const administradorDB = new AdministradorDB();
export default administradorDB;