import { getOwnerId } from '../../../components/auth/auth-provider';
import movimentoDB from '../../../dataManager/dtmMovimentos';
import moment from 'moment';
import estabelecimentoDB from '../../../dataManager/dtmEstabelecimento';


export async function buildChart(dataInicial, dataFinal) {

  const inicio = dataInicial.startOf('day').toDate();
  const fim = dataFinal.endOf('day').toDate();

  let movimentos = [];
  movimentos = await movimentoDB.getByPeriodo(inicio, fim)

  let reducedPorData = movimentos.reduce((result, arr) => {
    const id = secsToDate(arr.data.seconds)
    result[id] = result[id] || [];

    let creditoPre = 0;
    if (arr.creditoPre !== undefined) {
      creditoPre = (arr.creditoPre / 100);
    }

    let debito = arr.debito;
    let credito = arr.credito;

    // Compras canceladas não aparecem nos movimentos
    if (debito > 0 && arr.cancelada) {
      debito = 0;
    }

    // Compras estornadas não aparecem nos movimentos
    if (credito > 0 && arr.estorno) {
      credito = 0;
    }

    result[id] = {
      debito: result[id].debito ? result[id].debito + (debito / 100) : (debito / 100),
      credito: result[id].credito ? result[id].credito + (credito / 100) : (credito / 100),
      creditoPre: result[id].creditoPre !== undefined ? result[id].creditoPre + creditoPre : creditoPre,
      saldo: result[id].saldo ? result[id].saldo + (arr.saldo / 100) : (arr.saldo / 100),
      data: moment(arr.data.seconds * 1000),
    }

    return result;
  }, []);


  reducedPorData = fillEmptyDays(dataInicial, dataFinal, reducedPorData);
  /* eslint-disable no-unused-vars */
  let reducedPorEstabelecimento = movimentos.reduce((result, arr) => {
    if (arr.credito === 0 && (arr.creditoPre === undefined || arr.creditoPre === 0) && arr.autorizacao) {
      const id = arr.autorizacao ? arr.autorizacao.estabelecimento.cnpj : undefined;
      if (id) {
        result[id] = result[id] || [];
      }
      let tipoDebito = 'Débito';
      if (arr.valorDebitadoSaldo > 0 && arr.valorDebitadoSaldoPre > 0) {
        tipoDebito = 'Débito e Crédito';
      }
      else if (arr.valorDebitadoSaldoPre > 0) {
        tipoDebito = 'Crédito';
      }

      result[id] = {
        debito: result[id].debito ? result[id].debito + (arr.debito / 100) : (arr.debito / 100),
        estabelecimento: id,
        tipoDebito,
        estorno: result.estorno !== undefined ? result.estorno : false,
      }
    } else {
      result['credito'] = [];
      // Estorno não conta como crédito para este relatório
      if (!arr.estorno) {
        result['credito'] = result['credito'] ? result['credito'] + arr.credito : arr.credito;
      }
      if (arr.creditoPre !== undefined) {
        if (result['creditoPre'] === undefined) {
          result['creditoPre'] = 0;
        }
        result['creditoPre'] = result['creditoPre'] + arr.creditoPre;
      }
    }
    return result;
  }, []);


  let tableData = [];

  reducedPorEstabelecimento = await Promise.all(Object.values(reducedPorEstabelecimento).map(async (item) => {
    if (item.debito) {
      let key = 0;
      const cnpj = item.estabelecimento
      const estabelecimento = await estabelecimentoDB.getByCNPJ(cnpj);

      const element = {
        key: key,
        estabelecimento: {
          cnpj: cnpj,
          nome: estabelecimento[0].nomeFantasia
        },
        debito: 'R$ ' + item.debito.toFixed(2),
        tipoDebito: item.tipoDebito,
      }

      key++;

      tableData.push(element);
    }
  }));


  return {
    chartData: reducedPorData,
    tableData: tableData
  };
}

function fillEmptyDays(inicio, fim, info) {
  const days = fim.diff(inicio, "days");
  let novoInfo = [];

  for (let i = 0; i <= days; i++) {
    let data = moment(fim).startOf("day").subtract(i, "days");
    let item = Object.values(info).find((element) => element.data.startOf("day").diff(data, "days") === 0);
    if (item) {
      novoInfo.push({
        debito: item.debito,
        credito: item.credito,
        creditoPre: item.creditoPre,
        saldo: item.saldo,
        data: item.data.toDate()
      })
    } else {
      const momentData = moment(data)
      novoInfo.push({
        debito: 0,
        credito: 0,
        creditoPre: 0,
        saldo: 0,
        data: data.toDate()
      })
    }
  }
  novoInfo = novoInfo.sort((a, b) => a.data > b.data ? 1 : -1)
  return novoInfo;
}


function secsToDate(secs) {
  var t = moment(secs * 1000)
  return t.date() + "/" + (t.month() + 1);
}