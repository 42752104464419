import React, { Component } from 'react';
import { withRouter } from 'react-router'
import { Table, Button, Tooltip, Icon, Modal, Input } from 'antd';
import { getOwnerId } from '../../components/auth/auth-provider';

import ClienteCRUD from '../../components/cruds/clientes/ClienteCRUD';
import PageLayout from '../../components/layout/PageLayout';
import listObj from '../../components/listSearch/listSearch';
import clienteDB from '../../dataManager/dtmCliente';

import './Cliente.css';
import mensagem from '../../components/messages/message';

const { Search } = Input;


class Cliente extends Component {
    state = {
        loading: true,
        isSearching: false,
        clientes: [],
        clientesOriginal: [],
        convenioUnico: undefined,
        permissoes: {
            naoCadastrar: false,
            naoEditar: false
        }
    }

    constructor(props) {
        super(props);
        this.headerUsuarioElement = React.createRef();
        this.updateList = this.updateList.bind(this);
        this.applyFilter = this.applyFilter.bind(this);
        this.loadCustomers = this.loadCustomers.bind(this);
        this.loadMore = this.loadMore.bind(this);
    }

    async componentDidMount() {
        this.loadCustomers();
    }

    async loadCustomers() {
        const clientes = await clienteDB.get();
        this.setState({ clientes, clientesOriginal: clientes, loading: false });
    }

    async applyFilter(text) {
        this.setState({ loading: true });

        if (text === '') {
            this.setState({
                clientes: this.state.clientesOriginal,
                isSearching: false,
                loading: false
            });

            return;
        };

        const keys = [
            'razaoSocial',
            'nomeFantasia',
            'cnpj',
            'email',
            'endereco.telefone',
            'ativoStr'
        ];

        const clientes = listObj.search(this.state.clientesOriginal, text, keys);

        this.setState({
            clientes,
            isSearching: true,
            loading: false
        });
    }

    updateList(record) {
        if (!record) {
            return;
        }

        record['ativoStr'] = record['ativo'] ? 'Sim' : 'Não';

        let clientes = this.state.clientes;
        let registroEncontrado = false;

        this.state.clientes.forEach((item, index) => {
            if (item.key === record.key) {
                clientes[index] = record;
                registroEncontrado = true;
            }
        });

        if (!registroEncontrado) {
            clientes.push(record);
        }

        this.setState({ clientes, clientesOriginal: clientes });
    }

    async loadMore() {
        this.setState({ loading: true });

        const { clientes } = this.state;

        const lastDocId = clientes[clientes.length - 1].key;
        const data = await clienteDB.get(lastDocId);

        if (data.length === 0) {
            mensagem.avisar('Não existe mais clientes a serem carregados');
            this.setState({ loading: false });
            return;
        }

        const newTableData = clientes.concat(data);

        this.setState({
            loading: false,
            clientes: newTableData,
            clientesOriginal: newTableData
        });
    }

    handleCancel = () => {
        this.setState({ visible: false });
    };

    editarUsuarioElement(record) {
        this.headerUsuarioElement.current.editarUsuario(record);
    }

    render() {
        const columns = [
            {
                title: 'Razão Social',
                dataIndex: 'razaoSocial',
                key: 'razaoSocial',
                editable: true,
            },
            {
                title: 'Nome Fantasia',
                dataIndex: 'nomeFantasia',
                key: 'nomeFantasia',
            },
            {
                title: 'CNPJ',
                dataIndex: 'cnpj',
                key: 'cnpj',
            },
            {
                title: 'E-mail',
                dataIndex: 'email',
                key: 'email',
            },
            {
                title: 'Telefone',
                dataIndex: 'endereco.telefone',
                key: 'telefone',
            },
            {
                title: "Ativo?",
                dataIndex: "ativoStr",
                key: "ativoStr"
            },
            {
                title: '',
                key: 'action',
                render: (text, record) => (
                    <span>
                        <Tooltip placement="topLeft" title="Editar Cadastro">
                            <Icon
                                type="edit"
                                onClick={() => this.editarUsuarioElement(record)}
                            />
                        </Tooltip>
                    </span>
                ),
            },
        ];

        return (
            <div className="divTable">
                <PageLayout selectItem={'clientes'}>
                    <Table
                        title={() => (
                            <HeaderUsuario
                                naoCadastrar={this.state.permissoes.naoCadastrar}
                                ref={this.headerUsuarioElement}
                                applyFilter={this.applyFilter}
                                updateList={this.updateList}
                                convenioUnico={this.state.convenioUnico}
                            />
                        )}

                        footer={() => (
                            <div className='footerTable'>
                                <Button
                                    type='primary'
                                    loading={this.state.loading}
                                    onClick={this.loadMore}
                                    disabled={this.state.isSearching}
                                >
                                    Carregar mais Clientes
                                </Button>
                            </div>
                        )}

                        pagination={false}
                        columns={columns}
                        loading={this.state.loading}
                        dataSource={this.state.clientes}
                    />
                </PageLayout>
            </div>
        );
    }
}

class HeaderUsuario extends React.Component {

    state = {
        visible: false,
    };

    constructor(props) {
        super(props);
        this.novoClienteElement = React.createRef();
        this.novoCliente = this.novoCliente.bind(this);
        this.filterTable = this.filterTable.bind(this);
    }

    novoCliente() {
        this.novoClienteElement.current.show(false);
    }

    editarUsuario(record) {
        this.novoClienteElement.current.show(true, record);
    }

    filterTable(value) {
        const text = typeof value === "object" ? value.target.value : value;
        this.props.applyFilter(text);
    }

    render() {
        const novoClienteText = 'Novo Cliente';
        return (
            <div>
                <NovoCliente
                    ref={this.novoClienteElement}
                    updateList={this.props.updateList}
                    convenioUnico={this.props.convenioUnico}
                ></NovoCliente>

                <Button disabled={this.props.naoCadastrar} type="primary" onClick={this.novoCliente}><Icon className="icon" type="plus" /> {novoClienteText}</Button>

                <Search
                    placeholder="Procurar"
                    onSearch={this.filterTable}
                    onChange={this.filterTable}
                    style={{ marginLeft: "10px", width: 200 }}
                />
            </div>
        );
    }
}

class NovoCliente extends React.Component {
    state = {
        visible: false,
        confirmLoading: false,
        editMode: false,
        record: [],
        convenios: [],
    }

    constructor(props) {
        super(props);
        this.handleOk = this.handleOk.bind(this);
        this.handleCancel = this.handleCancel.bind(this);

        const ownerId = getOwnerId();
        if (!ownerId) {
            return;
        }
    }

    show(editMode, record) {
        if (this.props.convenioUnico) {
            this.setState({ convenioUnico: this.props.convenioUnico });
        }

        if (!record) {
            record = [];
        }

        this.setState({
            visible: true,
            confirmLoading: false,
            editMode: editMode,
            record: record
        });
    }

    handleOk(record) {
        this.setState({ visible: false });
        this.props.updateList(record);
    }

    handleCancel() {
        this.setState({ visible: false });
    }

    render() {
        return (
            <Modal
                title={'Cliente'}
                visible={this.state.visible}
                destroyOnClose={true}
                confirmLoading={this.state.confirmLoading}
                centered={true}
                onOk={this.handleOk}
                footer={null}
                closable={false}
            >
                <ClienteCRUD
                    handleOk={this.handleOk}
                    handleCancel={this.handleCancel}
                    editMode={this.state.editMode}
                    record={this.state.record}
                    convenios={this.state.convenios}
                    convenioUnico={this.state.convenioUnico}
                ></ClienteCRUD>

            </Modal>
        );
    }
}

export default withRouter(Cliente);