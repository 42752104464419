import { message } from 'antd';
import { fireBase } from '../../firebase/firebase';
import { authenticate, currentUser } from '../../components/auth/auth-provider';
import usuarioDB from '../../dataManager/dtmUsuario';


export async function autenticar(email, senha) {
    try {
        const obj = await fireBase.auth().signInWithEmailAndPassword(email, senha);
        let usuario = await usuarioDB.getByUserId(obj.user.uid);

        // Se não encontrar o usuário, verifico se é um beneficiário.
        // Caso não seja, sei que estou lidando com um Gestor
        if (!usuario || !usuario.is4CAdmin) {
            message.error('Usuário não tem permissão para acessar este sistema');
            fireBase.auth().signOut();
            return;
        }

        // Salvo em cache
        authenticate(obj.user);
        return true;


    } catch (error) {
        // Handle Errors here.
        if (error.code === 'auth/user-not-found') {
            message.error('Usuário não encontrado', 2.5);
        }
        if (error.code === 'auth/wrong-password') {
            message.error('Senha inválida');
        }

        if (error.code === 'auth/user-disabled') {
            message.error('Usuário desativado pelo administrador');
        }
        return false;

    }
}

export async function redefinePassword(email) {
    return new Promise((resolve) => {
        fireBase.auth().sendPasswordResetEmail(email)
            .then(function () {
                resolve({
                    success: true,
                    message: 'Ok',
                });
            }).catch(function (error) {
                debugger;
                if (error.code === "auth/invalid-email") {
                    error.message = 'Email inválido';
                }
                // An error happened.
                resolve({
                    success: false,
                    message: error.message,
                });
            });
    });
}

export function redirectJustIfUserIsAuthenticated() {
    const user = currentUser();
    if (user) {
        this.props.history.push('/dashboard');
    }
}