import DataManager from "./dtm";


class AuditoriaDB extends DataManager {

    state = {
        collection: 'x-auditorias',
        orderBy: [], // usado na classe pai
    }

}

const auditoriaDB = new AuditoriaDB();
export default auditoriaDB;