import React, { Component } from "react";
import { withRouter } from 'react-router'
import './Dashboard.css';
import PageLayout from '../../components/layout/PageLayout';
import vertteStorage from "../../components/localstorage/localstorage";
import { Line } from "react-chartjs-2";
import moment from "moment";
import administradorDB from "../../dataManager/dtmAdministrador";
import { currentUserCol, getOwnerId, isAdministrador } from "../../components/auth/auth-provider";
import convenioDB from "../../dataManager/dtmConvenio";
import { Spin } from 'antd';
import { buildChart } from "../relatorios/movimentos/Funcs-Movimentos";


const VERDE = '#32a852';
const LARANJA = '#ebb134';
const ROXO = '#a234eb';

class Dashboard extends Component {

    state = {
        loading: false,
        data: {
            labels: [],
            datasets: []
        },
        valores: {
            debito: 'Calculando...',
            credito: 'Calculando...',
            creditoPre: 'Calculando...',
        }
    }

    constructor(props) {
        super(props);
        this.buildChart = this.buildChart.bind(this);
        this.saveCache = this.saveCache.bind(this);
    }

    async componentDidMount() {
        this.buildChart();
    }

    saveCache(dataSet) {
        const todayAtMidNight = new Date();
        todayAtMidNight.setHours(23, 59, 59);
        vertteStorage.set('dashBoard', JSON.stringify(dataSet));
        vertteStorage.set('todayAtMidNight', todayAtMidNight);
    }

    async buildChart() {
        const conveniosIds = [];
        const isAdm = isAdministrador();
        if (isAdm) {
            const ownerId = getOwnerId();
            const userCol = currentUserCol();
            const convenios = await convenioDB.getByOwnerIdAndAdminId(ownerId, userCol.key);
            for (let index = 0; index < convenios.length; index++) {
                const convenio = convenios[index];
                conveniosIds.push({ key: convenio.key });
            }
        }
        let dataSet = vertteStorage.get('dashBoard');
        if (!dataSet) {
            let iniDate = new Date();
            iniDate.setDate(iniDate.getDate() - 15);
            iniDate = new Date(iniDate.getFullYear(), iniDate.getMonth(), iniDate.getDate(), 0, 0, 0);

            let endDate = new Date();
            endDate.setDate(endDate.getDate() - 1);

            iniDate = moment(iniDate);
            endDate = moment(endDate);

            dataSet = await buildChart(iniDate, endDate);
            this.saveCache(dataSet);
            
        } else {
            dataSet = JSON.parse(dataSet);
            let todayAtMidNight = vertteStorage.get('todayAtMidNight');
            if (!todayAtMidNight || new Date(todayAtMidNight) < new Date()) {
                let iniDate = new Date();

                const dtHj = new Date();
                dtHj.setHours(23, 59, 59);

                const differenceInTime = dtHj.getTime() - new Date(todayAtMidNight).getTime();
                var differenceInDays = differenceInTime / (1000 * 3600 * 24) -1;

                if (differenceInDays > 15) {
                    differenceInDays = 15
                } 

                iniDate.setDate(iniDate.getDate() - Math.trunc(differenceInDays));
                iniDate = new Date(iniDate.getFullYear(), iniDate.getMonth(), iniDate.getDate(), 0, 0, 0);

                let endDate = new Date();
                endDate.setDate(endDate.getDate() - 1);
                endDate.setHours(23, 59, 59);

                iniDate = moment(iniDate);
                endDate = moment(endDate);

                const thePast = await buildChart(iniDate, endDate);

                for (let index = 0; index < thePast.chartData.length; index++) {
                    dataSet.chartData.shift();                    
                }
                
                dataSet.chartData = dataSet.chartData.concat(thePast.chartData);
                this.saveCache(dataSet);
            }
        }

        const data = {
            // labels: ["01-03", "02-03", "03-03", "04-03", "05-03", "06-03"],
            labels: [],
            datasets: [
                {
                    label: "Débito",
                    data: [],
                    fill: true,
                    backgroundColor: "#f0e3c4",
                    borderColor: LARANJA,
                },
                {
                    label: "Crédito",
                    data: [],
                    fill: false,
                    hidden: true,
                    borderColor: VERDE,
                    backgroundColor: "#99b3a0",
                },
                {
                    label: "Crédito Pré",
                    data: [],
                    fill: false,
                    hidden: true,
                    borderColor: ROXO,
                    backgroundColor: "#cba9e2",
                }
            ]
        };

        let valores = {
            debito: 0,
            credito: 0,
            creditoPre: 0,
        }

        dataSet.chartData.forEach((item) => {
            valores.debito += item.debito;
            valores.credito += item.credito;
            valores.creditoPre += item.creditoPre;
            data.datasets[0].data.push(item.debito);
            data.datasets[1].data.push(item.credito);
            data.datasets[2].data.push(item.creditoPre);
            const date = new Date(item.data);
            data.labels.push(date.getDate() + "/" + (date.getMonth() + 1))
        });

        valores.debito = administradorDB.formatarMoeda(valores.debito);
        valores.credito = administradorDB.formatarMoeda(valores.credito);
        valores.creditoPre = administradorDB.formatarMoeda(valores.creditoPre);

        this.setState({ data, valores, loading: false });
    }




    render() {
        return (
            <div>
                <PageLayout selectItem={'dashboard'}>
                    <div className="divDashLayout">
                        {(this.state.loading === true) && (
                            <div className="divDashSpin">
                                <Spin size="large" />
                            </div>
                        )}

                        <label className='chartTitle'>Nossos clientes nos últimos 15 dias</label>

                        <div class="row divCardsValores">

                            <div class="col-lg-3 col-sm-6">
                                <div class="card-box bg-orange">
                                    <div class="inner">
                                        <h3> {this.state.valores.debito} </h3>
                                        <p> Débito </p>
                                    </div>
                                    <div class="icon">
                                        <i class="fa fa-arrow-down " aria-hidden="true"></i>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-3 col-sm-6">
                                <div class="card-box bg-green">
                                    <div class="inner">
                                        <h3> {this.state.valores.credito} </h3>
                                        <p> Crédito </p>
                                    </div>
                                    <div class="icon">
                                        <i class="fa fa-arrow-up " aria-hidden="true"></i>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-3 col-sm-6">
                                <div class="card-box bg-purple">
                                    <div class="inner">
                                        <h3> {this.state.valores.creditoPre} </h3>
                                        <p> Crédito Pré </p>
                                    </div>
                                    <div class="icon">
                                        <i class="fa fa-calendar" aria-hidden="true"></i>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div style={{
                            position: "relative",
                            margin: "auto",
                            width: "100%",
                            height: '21rem'
                        }}>
                            <Line className="chartDebitoCredito"
                                data={this.state.data}
                                //height="5px"
                                options={{
                                    maintainAspectRatio: false,
                                    responsive: true,

                                    legend: {
                                        display: true,
                                        position: 'bottom',
                                    },

                                    tooltips: {
                                        callbacks: {
                                            label: function (tooltipItem, data) {

                                                let label = data.datasets[tooltipItem.datasetIndex].label;
                                                let value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];

                                                value = administradorDB.formatarMoeda(value);
                                                return ' ' + label + ': ' + value
                                            }
                                        }
                                    },
                                    scales: {
                                        yAxes: [
                                            {
                                                ticks: {
                                                    beginAtZero: true,
                                                }

                                            }
                                        ]
                                    },
                                    layout: {
                                        padding: {
                                            top: 20,
                                            right: 40,
                                            left: 40,
                                            bottom: 20,
                                        }
                                    },
                                    plugins: {
                                        datalabels: {
                                            display: true,
                                            anchor: "end",
                                            align: "top",
                                            formatter: function (value, context) {
                                                if (value === undefined) {
                                                    return 0
                                                } else if (value === 0) {
                                                    return value;
                                                } else {
                                                    return value = administradorDB.formatarMoeda(value);
                                                }
                                            }
                                        },
                                    },
                                }}
                            />
                        </div>


                    </div>
                </PageLayout>
            </div >
        );
    }
}

export default withRouter(Dashboard);